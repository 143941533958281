import React, { useEffect, useState, useTransition, lazy, Suspense } from 'react'
import { Col, Row, Typography, Spin } from 'antd'
import LoadingOutlined from '@ant-design/icons/LoadingOutlined.js'
import AliceCarousel from 'react-alice-carousel'
import { Header, QuickService, SEO, SupportService, Footer } from './components'
import { RESPONSIVE_VIDEO_BREAK_POINTS, BLOGS_ARRAY, BETTER_SECTION_ARRAY, VIDEO_URLS } from '../constants'
import { useWindowSize } from '../../src/utils'

import HomeStyle from '../../styles/Home.module.css'
import PlayerStyle from './Player/Player.module.css'
import 'react-alice-carousel/lib/alice-carousel.css'
import HomeBG from './HomeBG/HomeBG.js'

const { Title, Text } = Typography
const Player = lazy(() => import("./Player/Player.js"));

const Home = () => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [videoList, setVideoList] = useState()
  const [loading, setLoading] = useState(false)
  const windowSize = useWindowSize().width;
  const isMobile = windowSize < 480;
  const [, startTransition] = useTransition();
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    if (VIDEO_URLS.length > 0 && !videoList) {
      setVideoList(VIDEO_URLS)
    }
    window.addEventListener('pageshow', () => {
      setLoading(false)
    })
  }, [videoList, loading])

  const onPlay = index => {
    //console.log("VIDEO PLAYING")
    const videoListCopy = [...videoList]
    videoListCopy[index].isPlaying = true
    setVideoList(videoListCopy)
    setSelectedIndex(index)
  }
  
  const onPause = index => {
    //console.log("VIDEO PAUSED")
    setSelectedIndex(index)
  }

  const onEnd = index => {
    //console.log("VIDEO ENDED")
    const videoListCopy = [...videoList]
    videoListCopy[index].isPlaying = false
    setVideoList(videoListCopy)
    setSelectedIndex(index)
  }

  const ThumbnailComponent = ({item, index}) => {
    return (
      <div className={`${PlayerStyle['thumbnail-container']}`}>
        <img
          alt="" //{ item.title }
          src={ item.thumbnail }
          className={`${PlayerStyle['thumbnailImage']}`}
          loading="lazy"
          onClick={() => {
                startTransition(() => {
                  onPlay(index);
                });
              }}
        />
        <img
          alt="Play Video"
          src="https://assets.cure.com/img/main-site/youtube-logo.svg"
          className={`${PlayerStyle['playIcon']}`}
          loading="lazy"
          onClick={() => {
            startTransition(() => {
              onPlay(index);
            });
          }}
        />  
      </div>
    )
  }

  const carousalItems = videoList
    ? videoList.map((item, index) => (
      <>
        <div className={`${PlayerStyle['player-container']}`}>
          {(!item.isPlaying || !hasLoaded) && (
            <ThumbnailComponent item={item} index={index}/>
          )}
          <Suspense fallback={<div></div>}>
            {
              item.isPlaying ? 
              <Player
                onEnd={() => onEnd(index)}
                onPause={() => onPause(index)}
                onPlay={() => onPlay(index)}
                playing={item.isPlaying}
                videoId= {`${item.videoID}`}
                setHasLoaded= {setHasLoaded} 
              /> : 
              null
            }
          </Suspense>
        </div>
        <p className={`${HomeStyle["video-title"]}`}>{ item.title }</p>
      </>
    ))
    : []

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  console.log('Loaded home', isMobile)

  return (
    <>
      <SEO
        description="Get a quote from CURE auto insurance in NJ, PA & MI - based on your driving record, not education, occupation, or credit score."
        title="Drive Well, Save More | CURE Auto Insurance"
        url=""
      />
      <>
        <Header setLoading={setLoading} />
        <Spin spinning={loading} indicator={antIcon}>
          <div className="container">
            <Row className={HomeStyle["home-page"]}>
              <Row className={HomeStyle["drive-safe-module"]}>
                <HomeBG 
                  className={ HomeStyle["forest-img"] }
                  alt = "CURE Auto Insurance - Road Banner"
                  fetchPriority = "high"
                  windowSize = { windowSize }
                  imgMobile={"https://assets.cure.com/img/main-site/forest_road_mobile-2.webp"}
                  img1000={"https://assets.cure.com/img/main-site/forest_road_1000.webp"}
                  img1300={"https://assets.cure.com/img/main-site/forest_road_1300.webp"}
                  img1500={"https://assets.cure.com/img/main-site/forest_road_resized.webp"}
                />
                
                <QuickService setLoading={setLoading} />
              </Row>
              <Row className={`${HomeStyle["makes-us-better-module"]}`}>
                <Title level={2}>What makes us better?</Title>
                <Text className={`${HomeStyle["sub-title"]}`}>
                  At CURE, we&apos;ve insured more than a million drivers and believe everyone deserves affordable car
                  insurance based primarily on their driving record.
                </Text>
                <Row className={`${HomeStyle["card-container"]}`}>
                  {BETTER_SECTION_ARRAY.map(({ title, description, className }) => (
                    <Col key={title} lg={8} md={24} sm={24} xl={{ span: 7, offset: 1 }} xs={24}>
                      <img 
                        alt={title} 
                        className={HomeStyle[className]} 
                        width={150}
                        height={150}
                      />
                      <div className={`${HomeStyle["inner-text-div"]}`}>
                        <h5 className={`${HomeStyle["card-title"]}`}>{title}</h5>
                        <div className={`sub-div ${HomeStyle["block-element"]}`}>{description}</div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Row>
              <Row className={`${HomeStyle["show-your-support-module"]}`}>
                <Col lg={8} md={8} sm={1} xl={6} xs={0} />
                <Col lg={8} md={8} sm={0} xl={6} xs={1} />
                <Col className={`${HomeStyle["support-service-container"]}`} lg={8} md={8} sm={22} xl={12} xs={22}>
                  <SupportService />
                </Col>
                <HomeBG 
                  className={ HomeStyle["cars-img"] }
                  alt = "Cars Insurance Rates - Road Banner"
                  windowSize = { windowSize }
                  imgMobile={"https://assets.cure.com/img/main-site/webp/v1/cars_on_road_mobile.webp"}
                  img1000={"https://assets.cure.com/img/main-site/cars_on_road_1000.webp"}
                  img1300={"https://assets.cure.com/img/main-site/cars_on_road_1300.webp"}
                  img1500={"https://assets.cure.com/img/main-site/cars_on_road_1300.webp"}
                />
              </Row>
              {isMobile ? 
              <Row className={`${HomeStyle["blogs-module-mobile"]}`}>
              <Col className={`${HomeStyle["videoContainer"]}`} sm={16} xs={24}>
                {videoList && (
                  <AliceCarousel
                    activeIndex={selectedIndex}
                    animationDuration={1000}
                    autoPlayDirection="ltr"
                    autoPlayInterval={3000}
                    autoPlayStrategy="all"
                    controlsStrategy="alternate"
                    disableAutoPlayOnAction={true}
                    infinite
                    items={carousalItems}
                    mouseTrackingEnabled={true}
                    responsive={RESPONSIVE_VIDEO_BREAK_POINTS}
                  />
                )}
              </Col>
            </Row>
            : <Row className={`${HomeStyle["blogs-module"]}`}>
                <Col className={`${HomeStyle["videoContainer"]}`} lg={{ span: 7 }} md={{ span: 7 }} sm={16} xl={{ span: 8 }} xs={20}>
                  <div>
                    {videoList && (
                      <AliceCarousel
                        activeIndex={selectedIndex}
                        autoPlayDirection="ltr"
                        autoPlayInterval={2000}
                        autoPlayStrategy="all"
                        controlsStrategy="alternate"
                        disableAutoPlayOnAction={true}
                        infinite
                        items={carousalItems}
                        mouseTrackingEnabled={true}
                        responsive={RESPONSIVE_VIDEO_BREAK_POINTS}
                      />
                    )}
                  </div>
                </Col>
                {BLOGS_ARRAY.map((blog, index) => (
                  <Col
                    key={blog.title}
                    className={`${HomeStyle["blog-card-container"]}`}
                    lg={{ span: 8 }}
                    md={{ span: 8 }}
                    sm={16}
                    xl={{ span: 8 }}
                    xs={20}
                  >
                    <div className={`${HomeStyle["blog-inner-container"]} ${index === 0 ? HomeStyle["blog-inner-left"] : HomeStyle["blog-inner-right"]}`}>
                      <a className={`${HomeStyle["blog-link"]}`} href={blog.link}>
                        <div className={`${HomeStyle["blog-holder"]}`}>
                          <img alt={blog.title} className={`${HomeStyle[blog.className]}`} />
                          <Title level={3}>{blog.title}</Title>
                          <Text className={`${HomeStyle["content"]}`}>{blog.content}</Text>
                        </div>
                      </a>
                    </div>
                  </Col>
                ))}
              </Row>}
            </Row>
            <Footer />
          </div>
        </Spin>
      </>
    </>
  )
}

export default Home
